
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$YieldVestor-primary: mat.define-palette(mat.$indigo-palette);
$YieldVestor-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$YieldVestor-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$YieldVestor-theme: mat.define-light-theme((
  color: (
    primary: $YieldVestor-primary,
    accent: $YieldVestor-accent,
    warn: $YieldVestor-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($YieldVestor-theme);

/* You can add global styles to this file, and also import other style files */
@import "typeface-poppins";
@tailwind base;
@tailwind components;
@tailwind utilities;

// body,
// html {
//   overflow: hidden !important;
// }
// :host ::ng-deep ::-webkit-scrollbar {
//   width: 6px !important;
//   border-radius: 8px !important;
// }

// /* Track */
// :host ::ng-deep ::-webkit-scrollbar-track {
//   background: #f1f1f1 !important;
// }

// /* Handle */
// :host ::ng-deep ::-webkit-scrollbar-thumb {
//   background: #888 !important;
// }

// /* Handle on hover */
// :host ::ng-deep ::-webkit-scrollbar-thumb:hover {
//   background: #555 !important;
// }

.screen {
  @apply md:max-w-screen-md lg:md:max-w-screen-lg xl:md:max-w-screen-xl mx-auto;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
